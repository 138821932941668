import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/sitetabas.jpg";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  function handleClick() {
    window.location.href = "https://wa.me/905326489373?text=Merhaba%20Tabas%20satın%20almak%20istiyorum";
  }
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            İster Tabure, İster Baston
          </h1>
          <p className="primary-text">
            İhtiyacınız olduğunda Tabure, İhtiyacınız olduğunda Baston
          </p>
          <button className="secondary-button" onClick={handleClick}>
            Sipariş Ver! <FiArrowRight />{" "}
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
